/* Login */
#login_wrapper {
    display: flex !important;
    align-items: center !important;
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    background-color: white !important;
}

.form-signin {
  width: 100% !important;
  max-width: 330px !important;
  padding: 15px !important;
  margin: auto !important;
}

.form-signin .checkbox {
  font-weight: 400 !important;
}

.form-signin .form-floating:focus-within {
  z-index: 2 !important;
}

.form-signin input[type="email"] {
  margin-bottom: -1px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-signin input[type="password"] {
  margin-bottom: 10px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.bd-placeholder-img {
    font-size: 1.125rem !important;
    text-anchor: middle !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem !important;
    }
  }
/* ----- */